import { TokenEngine } from '@vp/vp-js-token-engine';
import { HTMLIdentifier } from '../services/htmlIdentifier';
import { PresentationTokenFactory } from '@vp/vp-js-token-engine';
import { availablesLocales } from '../services/availablesLocales';

export const tokenEngineFactory = {
  create: (culture: string, tenant?: string) => new TokenEngine(culture, tenant),
};
export const htmlIdentifier = new HTMLIdentifier();
export const pricePresentationTokenFactory = new PresentationTokenFactory();

export const locales = availablesLocales();
