import * as React from 'react';
import { IUnitPrice } from '@vp/vp-js-token-engine';
import PricePresentation, { IPricePresentationBase } from '../PricePresentation';

export default class PricePerPackage extends React.Component<IPricePresentationBase<IUnitPrice>> {
  public render(): JSX.Element {
    return (
      <PricePresentation
        classNameWrapper={this.props.classNameWrapper}
        type="pricePerPackage"
        pricingInfo={this.props.pricingInfo}
        tenant={this.props.tenant}
        placeHolderText={this.props.placeHolderText}
        isTile={this.props.isTile}
      />
    );
  }
}
