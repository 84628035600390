import * as React from 'react';
import PricePresentation, { IPricePresentationBase } from '../PricePresentation';
import { IPlusOrMinus } from '@vp/vp-js-token-engine';

export default class PlusOrMinus extends React.Component<IPricePresentationBase<IPlusOrMinus>> {
  public render(): JSX.Element {
    return (
      <PricePresentation
        classNameWrapper={this.props.classNameWrapper}
        type="plusOrMinus"
        pricingInfo={this.props.pricingInfo}
        placeHolderText={this.props.placeHolderText}
      />
    );
  }
}
