import * as React from 'react';
import { attributesToProps, domToReact } from 'html-react-parser';
import PricePresentation, { IPricePresentationBase } from '../PricePresentation';
import { IRangePrice } from '@vp/vp-js-token-engine';

export interface IRangePriceProps {
  splitInMultipleLines?: boolean;
}
export default class RangePrice extends React.Component<IRangePriceProps & IPricePresentationBase<IRangePrice>> {
  public render(): JSX.Element {
    const options = {
      replace: (node: any) => {
        if (node.attribs && node.attribs.class === 'list-price-wrapper') {
          const props = attributesToProps(node.attribs);
          const displayBlockStyle = { display: 'block' };

          return (
            <span {...props} style={displayBlockStyle}>
              {domToReact(node.children, options)}
            </span>
          );
        }
        return node;
      },
    };

    return (
      <PricePresentation
        classNameWrapper={this.props.classNameWrapper}
        type="rangePrice"
        pricingInfo={this.props.pricingInfo}
        placeHolderText={this.props.placeHolderText}
        tenant={this.props.tenant}
        replaceOptions={!this.props.splitInMultipleLines ? undefined : options}
        isTile={this.props.isTile}
      />
    );
  }
}
