import * as React from 'react';
import { HTMLReactParserOptions } from 'html-react-parser';
import isEqual from 'lodash.isequal';
import VpTokenizedFragment from '../VpTokenizedFragment';
import { pricePresentationTokenFactory } from '../infrastructure/Container';
import { IPresentation, PricePresentationType } from '@vp/vp-js-token-engine';

export interface IPricePresentationBase<T> {
  pricingInfo: T;
  tenant?: string;
  placeHolderText: string;
  classNameWrapper?: string;
  isTile?: boolean;
}

export interface IPricePresentationProps extends IPricePresentationBase<IPresentation> {
  type: PricePresentationType;
  replaceOptions?: HTMLReactParserOptions;
}

interface IPricePresentationState {
  token: string;
}

export default class PricePresentation extends React.Component<IPricePresentationProps, IPricePresentationState> {
  public state = {
    token: '',
  };

  private _isMounted: boolean = false;

  public async componentDidMount() {
    this._isMounted = true;
    await this.refreshToken();
  }

  public async componentDidUpdate(prevProps: IPricePresentationProps) {
    if (!isEqual(prevProps, this.props)) {
      await this.refreshToken();
    }
  }

  public async componentWillUnmount() {
    this._isMounted = false;
  }

  public render(): JSX.Element {
    const elem = (
      <VpTokenizedFragment
        culture={this.props.pricingInfo.culture}
        tenant={this.props.tenant}
        tokenText={this.state.token}
        placeHolderText={this.props.placeHolderText}
        replaceOptions={this.props.replaceOptions}
        isTile={this.props.isTile}
      />
    );

    if (this.props.classNameWrapper) {
      return <div className={this.props.classNameWrapper}>{elem}</div>;
    }

    return elem;
  }

  private async refreshToken() {
    if (!this._isMounted) {
      return; // if component is unmounted then we will not proceed with the API call or setting state variable
    }

    try {
      const token = await this.getToken();
      if (this._isMounted) {
        this.setState({ token });
      }
    } catch (error) {
      console.error('ERROR', error.message);
      if (this._isMounted) {
        this.setState({ token: '' });
      }
    }
  }

  private async getToken(): Promise<string> {
    return await pricePresentationTokenFactory.generateToken(this.props.pricingInfo, this.props.type);
  }
}
