import * as React from 'react';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import isEqual from 'lodash.isequal';
import { tokenEngineFactory } from './infrastructure/Container';
import { htmlIdentifier } from './infrastructure/Container';
import TokenEngine from '@vp/vp-js-token-engine';

export interface ITokenizedFragmentProps {
  culture: string;
  tenant?: string;
  tokenText: string;
  placeHolderText: string;
  replaceOptions?: HTMLReactParserOptions;
  isTile?: boolean;
}

export interface ITokenizedFragmentState {
  content: string;
}

export default class VpTokenizedFragment extends React.Component<ITokenizedFragmentProps, ITokenizedFragmentState> {
  private tokenEngine: TokenEngine;

  private _isMounted: boolean = false;
  public constructor(props: ITokenizedFragmentProps) {
    super(props);
    this.state = {
      content: '',
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.refreshContent();
  }

  public componentDidUpdate(prevProps: ITokenizedFragmentProps) {
    if (!isEqual(prevProps, this.props)) {
      const localeChanged = prevProps.culture !== this.props.culture;
      this.refreshContent(localeChanged);
    }
  }

  public async componentWillUnmount() {
    this._isMounted = false;
  }

  public render(): JSX.Element | string {
    let renderedContent: string;
    if (this.state.content !== '') {
      renderedContent = this.state.content;
    } else {
      renderedContent = this.props.placeHolderText;
    }

    if (htmlIdentifier.identifyHTML(renderedContent)) {
      return <span>{parse(renderedContent, this.props.replaceOptions)}</span>;
    }
    return renderedContent;
  }

  private refreshContent(localeChanged?: boolean) {
    if (!this._isMounted) {
      return;
    }
    if (!this.props.culture) {
      console.error('ERROR culture not specified for VpTokenizedFragment');
      return;
    }

    if (localeChanged || !this.tokenEngine) {
      this.tokenEngine = tokenEngineFactory.create(this.props.culture, this.props.tenant);
    }

    this.tokenEngine
      .render(this.props.tokenText, { isTile: this.props.isTile })
      .then((rendered: string): void => {
        if (this._isMounted) {
          this.setState({ content: rendered });
        }
      })
      .catch((error: any) => {
        console.error('ERROR rendering token in VPTokenizedFragment', error.message);
        if (this._isMounted) {
          this.setState({ content: '' });
        }
      });
  }
}
