import * as React from 'react';
import TokenEngine from '@vp/vp-js-token-engine';

export interface ITokenReplaceProps {
  tokenizedText: string;
  contextVariables: object;
}

export default class TokenReplace extends React.Component<ITokenReplaceProps> {
  private tokenEngine = new TokenEngine();

  public render(): string {
    return this.tokenEngine.replace(this.props.tokenizedText, this.props.contextVariables);
  }
}
