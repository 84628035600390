import * as React from 'react';
import { attributesToProps, domToReact } from 'html-react-parser';
import PricePresentation, { IPricePresentationBase } from '../PricePresentation';
import { ICouponCallout } from '@vp/vp-js-token-engine';

export interface ICouponCalloutProps {
  onCouponClick?: () => void;
}

export default class CouponCallout extends React.Component<
  ICouponCalloutProps & IPricePresentationBase<ICouponCallout>
> {
  public render(): JSX.Element {
    const options = {
      replace: (node: any) => {
        if (node.attribs && node.attribs.class === 'promo-code') {
          const props = attributesToProps(node.attribs);
          const underlineText = { textDecoration: 'underline' };
          props['className'] = `${props['className']} stylized-button-skin-unstyled`;

          return (
            <button {...props} style={underlineText} onClick={this.props.onCouponClick}>
              {domToReact(node.children, options)}
            </button>
          );
        }
        return node;
      },
    };

    return (
      <PricePresentation
        classNameWrapper={this.props.classNameWrapper}
        type="couponCallout"
        pricingInfo={this.props.pricingInfo}
        placeHolderText={this.props.placeHolderText}
        tenant={this.props.tenant}
        replaceOptions={!this.props.onCouponClick ? undefined : options}
        isTile={this.props.isTile}
      />
    );
  }
}
